<template>
  <div class="content-wrap">
    <Header />
    <div class="search-box">
      <div class="search">
        <ul>
          <li>
            <AutoComplete v-model="name" :data="nameList" @on-search="searchName" placeholder="请输入机构名称"
              class="width-300">
              <Option v-for="item in nameList" :value="item" :key="item">{{
                item
              }}</Option>
            </AutoComplete>
          </li>
          <li>
            <Select placeholder="选择填报进度" class="width-300" v-model="status">
              <Option value="未完成">未完成</Option>
              <Option value="完成">完成</Option>
            </Select>
          </li>
          <li>
            <DatePicker format="yyyy" v-model="year" type="year" placeholder="选择填报年份" class="width-300" />
          </li>
          <li>
            <div class="btn search-btn flex-center-center" @click="handleSearch">
              <div class="img"></div>
            </div>
            <div class="btn reset-btn flex-center-center" @click="handleReset" v-if="name != '' || status != ''">
              <div class="img"></div>
            </div>
          </li>
        </ul>
        <div>
          <div>
            <span>共 <span class="bold">{{ total }}</span>个</span>
          </div>
        </div>
      </div>
    </div>
    <div class="table" v-if="total > 0">
      <Table :columns="columns" :data="tableData">
        <template #action="{ row }">
          <Select v-model="row.actionType" @on-change="handleSelect(row)" :transfer="true">
            <Option value="查看数据目录">查看数据目录</Option>
            <Option value="下载盖章文件" v-if="row.down">下载盖章文件</Option>
          </Select>
        </template>
      </Table>

      <div class="page" v-if="total > 10">
        <Page :total="total" show-elevator show-total @on-change="changePage" :page-size="pageSize" ref="pages" />
      </div>
    </div>
    <noData v-if="total == 0" />
    <Footer />
  </div>
</template>
        
<script setup>
import { ref, inject, onBeforeUnmount, onMounted, watch } from "vue";
import Header from "./components/situationHeader.vue";
import Footer from "@/components/footer.vue";
import noData from "./components/noData.vue";
import bus from "@/utils/bus.js";
import { useRouter } from "vue-router";
import DownFiles from "@/utils/dowFile";
import { Message } from "view-ui-plus";

let type = ref("");
let columns = [
  {
    title: "机构名称",
    key: "orgName",
  },
  {
    title: "年度",
    key: "annual",
    width: 100,
  },
  {
    title: "填报进度",
    key: "fillingProgress",
    width: 160,
  },
  {
    title: "待提交(条)",
    key: "beSubmitCount",
    width: 160,
  },
  {
    title: "待复核(条)",
    key: "beReviewCount",
    width: 160,
  },
  {
    title: "复核通过(条)",
    key: "completedCount",
    width: 160,
  },
  {
    title: "操作",
    slot: "action",
    width: 160,
  },
];

let tableData = ref([]);
let page = ref(1);
let pageSize = ref(10);
let total = ref(0);
let pages = ref(null);
let router = useRouter();

const changePage = (val) => {
  page.value = val;
  getTableData();
};
let axios = inject("axios");
const getTableData = () => {
  let url = `/setting/filling.information`;
  let year1 = ''

  if (year.value) {
    year1 = year.value.getFullYear()
  }
  console.log(year.value)
  let params = {
    page: page.value,
    size: pageSize.value,
    orgName: name.value,
    plan: status.value,
    year: year1,
  };

  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      if (data != null) {
        tableData.value = data.data.map((item) => {
          return {
            ...item,
            actionType: "",
          };
        });
        total.value = data.total;
      }
    }
  });
};

const handleSearch = () => {
  page.value = 1;
  if (pages.value != null) {
    pages.value.currentPage = 1;
  }
  getTableData();
};
const handleReset = () => {
  status.value = "";
  name.value = "";
  year.value = "";
  handleSearch();
};

let name = ref("");
let nameList = ref([]);
const searchName = (value) => {
  let url = `/org/name`;
  let params = {
    orgName: value,
  };
  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      nameList.value = res.data.data;
    } else {
      nameList.value = [];
    }
  });
};
let status = ref("");
let year = ref("");

const handleSelect = (row) => {
  let type = row.actionType;
  if (type == "查看数据目录") {
    router.push({
      name: "datalist",
      query: {
        name: row.orgName,
      },
    });
  } else {
    exportList(row);
  }
};

//导出文件
const exportList = (row) => {
  let url = `/setting/down.file`;
  let params = {
    orgName: row.orgName,
    year: row.annual,
  };
  axios
    .get(url, {
      params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      },
      responseType: "blob",
    })
    .then((res) => {
      if (res.status == 200) {
        const blob = res.data;
        // 获取文件名
        const content = res.headers["content-disposition"];
        const fileName = content && content.split(";")[1].split("filename=")[1];
        console.log(fileName);
        DownFiles(blob, fileName);
      }
    });
};

onMounted(() => {
  handleSearch();
});
onBeforeUnmount(() => { });
</script>
        
    <style lang="scss" scoped>
@import "@/views/dataFilling/index.scss";
@import "./index.scss";
.edit .img {
  width: 14px;
  height: 14px;
  background: url(../../assets/img/编辑-默认.png) no-repeat;
  background-size: cover;
}
.edit:hover .img {
  width: 14px;
  height: 14px;
  background: url(../../assets/img/编辑-选中.png) no-repeat;
  background-size: cover;
}
</style>